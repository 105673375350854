import validationMixin from '@/mixins/validation'
import chapters from '@/components/video-chapters/index.vue'
const gradients = [
  ['#222'],
  ['#42b3f4'],
  ['red', 'orange', 'yellow'],
  ['purple', 'violet'],
  ['#00c6ff', '#F0F', '#FF0'],
  ['#f72047', '#ffd200', '#1feaea']
]

export default {
  name: 'dashboard',
  mixin: [validationMixin],
  data () {
    return {
      width: 2,
      radius: 10,
      padding: 8,
      lineCap: 'round',
      gradient: gradients[5],
      value: [0, 2, 5, 9, 5, 10, 3, 5, 0, 0, 1, 8, 2, 9, 0],
      gradientDirection: 'top',
      gradients,
      fill: false,
      type: 'trend',
      autoLineWidth: false,

      valid: false,
      payload: {
        packages: '',
        endedAt: '',
        retakeTest: false
      },
      list: [],
      showRemoveDialog: false,
      deletedItem: null,
      updateList: true
    }
  },
  components: {
    chapters
  }
}
